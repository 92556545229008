import React from 'react'
import { graphql } from 'gatsby'

import { About } from '../components/Pages'

const WhatWeDo = ({ data: { blocks, seo } }) => {
  const sections = blocks.edges.map(({ node }) => ({ ...node.frontmatter, html: node.html }))
  return <About sections={sections} seo={seo} />
}

export default WhatWeDo

export const query = graphql`
  query WhatWeDo($locale: String!, ) {
    blocks: allMarkdownRemark(
      filter: {
        frontmatter: { locale: { eq: $locale } }
        fileAbsolutePath: {regex: "/(content)\/.*\\.md$/"}
      }
      sort: { fields: [frontmatter___number], order: ASC }
    ) {
      edges {
        node {
          ...BlocksData
        }
      }
    }
    seo: allFile(filter: {sourceInstanceName: {eq: "pages"}, name: {eq: $locale}}) {
      nodes {
        ...SeoData
      }
    }
  }
`;
